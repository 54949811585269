@use '@angular/material' as mat;
@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import '@angular/material';
@import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
@import 'mapbox-gl/dist/mapbox-gl.css';
@import 'material-icons/iconfont/material-icons';
@import 'ngx-toastr/toastr';
@import 'source-sans-pro/source-sans-pro.css';
@import 'preload-view';
@import 'theme';
@import 'vendors';

@include mat.core();
$custom-typography: mat.m2-define-typography-config(
    $font-family: (
        'Source Sans Pro',
        sans-serif,
    ),
);
$custom-theme: mat.m2-define-light-theme(
    (
        typography: $custom-typography,
    )
);
@include mat.all-component-themes($custom-theme);

html,
body {
    margin: 0;
    position: fixed;
    overflow: hidden;
}

* {
    font-family: 'Source Sans Pro', sans-serif;
    box-sizing: border-box;
}

a {
    color: var(--color-brand-secondary);

    &:hover,
    &:visited {
        color: var(--color-brand-primary);
    }
}

.pills {
    margin: 8px 0;

    .pill-option {
        border: none;
        outline: none;
        font-weight: normal;
        font-size: 14px;
        border-radius: 99999999999px;
        padding: 2px 8px;
        transition: all 0.15s ease;
        min-height: 0;
        cursor: pointer;
        color: var(--color-brand-primary);
        background-color: var(--color-on-brand-primary);

        &.active {
            font-weight: bold;
            color: var(--color-on-brand-primary);
            background-color: var(--color-brand-primary);
        }
    }
}
